import React from "react";

const Arrow = ({
  polyline,
  width = "48px",
  height = "48px",
  strokeWidth = "2",
}) => {
  return (
    <svg
      stroke="currentColor"
      fill="none"
      strokeWidth={strokeWidth}
      viewBox="0 0 24 24"
      // stroke-linecap="round"
      // stroke-linejoin="round"
      width={width}
      height={height}
      stroke="#e3672a"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polyline points={polyline}></polyline>
    </svg>
  );
};

export default Arrow;
