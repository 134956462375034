import React, { useEffect, useState, useRef } from 'react'
import Image from 'components/Image'
import { useMeasure } from 'react-use'
import { animated } from 'react-spring'
import { Link } from 'gatsby'

function safeWidow (cb) {
  if (typeof window !== 'undefined') {
    return cb()
  }
}

function verifyPath ({ page, key, name }) {
  return (
    page.slug.includes(key) &&
    page.name === name &&
    safeWidow(() => window.location.pathname.includes(key))
  )
}

const LinkItem = ({ page, path, color, dot = true }) => {
  const fontWeight =
    verifyPath({ page, key: 'book-design', name: 'Book Design' }) ||
    verifyPath({ page, key: 'shop', name: 'Shop' })
      ? 'font-bold'
      : 'font-regular'
  const to = page.slug === '/' ? '/' : page.slug + '/'
  return (
    <Link to={to} key={page} activeClassName='active' replace>
      <li
        className={`relative flex items-center text-xs font-normal transition-all duration-300 cursor-pointer hover:font-bold mb-2 text-right ${fontWeight}`}
      >
        <div className={`${fontWeight} uppercase text-${color}`}>
          {page.name}
        </div>{' '}
        {dot && (
          <div className='absolute -right-5'>
            <div className='menu-dot'></div>
          </div>
        )}
      </li>
    </Link>
  )
}

const sortOrder = { '/': 0, '/us': 1, '/book-design': 2, '/shop': 3 }
const sortPages = (a, b) => {
  return sortOrder[a.slug] - sortOrder[b.slug]
}
const maxItems = 5
const Menu = ({
  pages,
  logo,
  logoMobile,
  path,
  relations,
  navigate,
  overlayedPaths,
  categories,
  location
}) => {
  const [color, setColor] = useState('black')
  // const calculateLogoColor = () => {
  //   setColor(null)
  //   if (
  //     overlayedPaths.includes(window.location.pathname) &&
  //     window.innerWidth < 768
  //   ) {
  //     setColor('white')
  //     return
  //   }
  //   setColor('black')
  // }
  // useEffect(() => {
  //   calculateLogoColor()
  // }, [location])
  // useEffect(() => {
  //   setTimeout(() => {
  //     calculateLogoColor()
  //   }, 100)
  //   window.addEventListener('resize', calculateLogoColor)
  //   return () => {
  //     window.removeEventListener('resize', calculateLogoColor)
  //   }
  // }, [])
  const ulClassName = `flex flex-col items-end my-12 mb-4 text-${color} md:text-black mr-logoSm xl:mr-logo relative`
  return (
    <nav>
      <a href='/' className='relative min-h-full mb-8' style={{ height: 300 }}>
        {color && (
          <Image
            className='w-3/5 ml-33p'
            image={color === 'black' ? logo : logoMobile}
            style={{ maxWidth: 'none' }}
          />
        )}
      </a>
      <ul className={ulClassName}>
        {pages
          .filter(i => i.name !== '404')
          .sort(sortPages)
          .map(page => {
            return (
              <LinkItem path={path} page={page} key={page.slug} color={color} />
            )
          })}
        {/* {collections.map((page) => {
          return <LinkItem path={path} page={page} key={page.slug} />;
        })} */}
      </ul>
      {path.includes('/book-design') && (
        <SubMenu
          relations={relations}
          path={path}
          ulClassName={ulClassName}
          navigate={navigate}
        />
      )}
      {path === '/shop' ||
        (path === '/shop/' && (
          <FilterMenu
            categories={categories}
            ulClassName={ulClassName}
            location={location}
          />
        ))}
    </nav>
  )
}

const SubMenu = ({ relations, ulClassName, path, navigate }) => {
  const [liRef, { height: liHeight }] = useMeasure()
  const ulRef = useRef(null)
  // const [open, setOpen] = useState(false);
  // const defaultHeight =
  //   maxItems > relations.length ? relations.length * (liHeight * 2) : 120;
  // const expand = useSpring({
  //   height: open ? `${liHeight * relations.length}px` : `${defaultHeight}px`,
  //   overflow: "hidden",
  // });
  useEffect(() => {
    if (path === '/book-design') {
      const to = ulRef.current.querySelector('a').getAttribute('href')
      // window.location = to;
      // navigate(to, { replace: false });
    }
    // if (open) {
    //   setTimeout(() => {
    //     ulRef.current.scrollIntoView({ top: true, behavior: "smooth" });
    //   }, 500);
    // }
  }, [])
  // }, [open]);
  // const handleHover = ({ value, target }) => {
  //   if (defaultHeight < liHeight * relations.length) {
  //     setOpen(value);
  //   }
  // };
  return (
    <animated.ul
      className={ulClassName}
      ref={ulRef}
      // style={expand}
      // onMouseEnter={({ target }) => handleHover({ value: true, target })}
      // onMouseLeave={({ target }) => handleHover({ value: false, target })}
    >
      {relations
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(relation => (
          <div ref={liRef} key={relation.slug}>
            <LinkItem path={path} page={relation} dot={false} />
          </div>
        ))}
    </animated.ul>
  )
}

const FilterMenu = ({ categories, ulClassName, location }) => {
  return (
    <ul className={ulClassName}>
      {categories.map(category => {
        const isActive = location.hash === `#${category}`
        return (
          <li key={category} className='mb-2 uppercase'>
            <Link
              to={isActive ? '/shop' : `#${category}`}
              activeClassName='active'
              className={isActive ? 'font-bold' : 'font-regular'}
            >
              {category}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default Menu
