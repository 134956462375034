import React from "react";
import parse from "html-react-parser";

const Text = (props) => {
  return (
    <div className="flex flex-col items-start text-black">
      {parse(props.text)}
    </div>
  );
};

export default Text;
