import React, { useState, useRef, useEffect } from "react";

const Vimeo = ({ id, name, type }) => {
  const [show, setShow] = useState(false);
  const iframeContainer = useRef(null);
  function handleVideo() {
    setShow(prev => !prev);
  }
  useEffect(() => {
    setShow(false);
  }, [id]);
  useEffect(() => {
    if (show && iframeContainer.current) {
      iframeContainer.current?.scrollIntoView();
    }
  }, [show]);
  return (
    <div>
      <div className="flex items-center cursor-pointer" onClick={handleVideo}>
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mr-2"
        >
          <path
            d="M12.5 6L0.5 12V0L12.5 6ZM1.04545 11.1575L11.3611 6L1.04545 0.8425V11.1575Z"
            fill="black"
          />
        </svg>
        <div className="mt-1 mr-1">{!show ? "PLAY" : "HIDE"} VIDEO:</div>
        <div className="mt-1 font-bold" dangerouslySetInnerHTML={{ __html: name.content || name }}>{}</div>
      </div>
      {show && (
        <>
          <div
            style={{ padding: "56.25% 0 0 0", position: "relative" }}
            ref={iframeContainer}
          >
            {
              !type || type === 'Vimeo' ?

                <iframe
                  src={`https://player.vimeo.com/video/${id}?h=de3caefe1d&autoplay=1&loop=1&byline=0&portrait=0`}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                ></iframe>
                :
                <iframe
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                  src={`https://www.youtube.com/embed/${id}`}
                  title="YouTube video player"
                  frameborder="0"
                  autoplay
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                ></iframe>
            }
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </>
      )}
    </div>
  );
};

export default Vimeo;
