import React, { useState, useRef, useEffect } from "react";
import Image from "components/Image";
import Arrow from "components/Arrow";

const BookGallery = ({ i1, i2, i3, i4, i5, i6, i7, i8, i9, i10, path }) => {
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(false);
  const images = [i1, i2, i3, i4, i5, i6, i7, i8, i9, i10];
  useEffect(() => {
    setCurrent(0);
  }, [path]);
  const arrowClassName = "cursor-pointer";

  useEffect(() => {
    if (!images[current]) {
      setLoading(false)
    }
  }, [current])
  const handleChange = (type) => {
    setLoading(true);
    switch (type) {
      case "next":
        setCurrent((i) => {
          if (i < images.length - 1) {
            return i + 1;
          }
          return 0;
        });
        break;
      case "prev":
        setCurrent((i) => {
          if (i - 1 >= 0) {
            return i - 1;
          }
          return images.length - 1;
        });
        break;
    }
  };
  return (
    <article className="flex w-full" style={{ marginTop: '2.5rem' }}>
      <div
        className={`relative flex flex-col w-full h-full`}
      >
        {loading && (
          <div className="absolute inset-0 flex items-center justify-center w-full h-full flex-columns">
            <div className="w-24 h-24 blink"></div>
          </div>
        )}
        <div
          className="flex text-black"
          style={{ zIndex: 3 }}
        >
          <div className={arrowClassName} onClick={() => handleChange("prev")}>
            <Arrow polyline="15 18 9 12 15 6" width="32px" height="32px" />
          </div>
          <div className={arrowClassName} onClick={() => handleChange("next")}>
            <Arrow polyline="9 18 15 12 9 6" width="32px" height="32px" />
          </div>
        </div>
        <div className="w-full h-full">
          {images
            .filter((_, index) => index === current)
            .map((image, index) => (
              <React.Fragment key={index}>
                {
                  image && image.src ?
                    <Image
                      key={image.src}
                      className="w-full h-full"
                      image={image}
                      width={1500}
                      background={false}
                      onLoad={() => {
                        setLoading(false);
                      }}
                      imgStyle={{
                        width: "100%",
                        height: "100%",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        objectFit: "contain",
                      }}
                    /> :
                    <>
                      <p>No hay imágen seleccionada</p>
                    </>
                }
              </React.Fragment>
            ))}
        </div>
      </div>
    </article>
  );
};

export default BookGallery;
