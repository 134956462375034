import React, { useCallback, useEffect, useRef } from "react";
import { Link } from "gatsby";
import Image from "components/Image";
import { useState } from "react";

const SideLink = ({ link, image }) => {
  const linkRef = useRef(null);
  const [right, setRight] = useState(null);
  const calculateRight = useCallback(() => {
    const { width, height } = linkRef.current.getBoundingClientRect();
    setRight(-height / 2 + width / 2);
  }, []);
  useEffect(() => {
    calculateRight();
    window.addEventListener("resize", calculateRight);
    return () => {
      window.removeEventListener("resize", calculateRight);
    };
  }, []);
  return (
    <div className="relative flex items-end justify-end h-2 md:justify-start">
      <div
        ref={linkRef}
        className="transform md:absolute md:-rotate-90"
        style={{ opacity: !right ? 0 : 1, right, top: 0 }}
      >
        <a href={link.href} className="flex items-center" target="_blank">
          {link.text}
          <div className="w-6 ml-2">
            <Image width={300} image={image} />
          </div>
        </a>
      </div>
    </div>
  );
};

export default SideLink;
