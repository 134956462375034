import React, { useState } from 'react'
import Image from 'components/Image'

function Overlay({ image, closeOverlay }) {
  const [ratio, setRatio] = useState(null)
  const handleLoad = (image) => {
    const { naturalWidth, naturalHeight } = image
    setRatio(naturalWidth / naturalHeight)
  }
  const containerClassName =
    ratio >= 1
      ? 'flex justify-center w-full h-full py-2 items-center'
      : 'flex justify-center w-30p py-2 items-center'
  const handleCloseOutside = ({ target }) => {
    if (target.getAttribute('data-overlay')) {
      closeOverlay()
    }
  }
  return (
    <>
      <div
        className="absolute flex items-center justify-center p-1 rounded-full cursor-pointer right-4 top-4 hover:border-black hover:border-1"
        style={{
          zIndex: 10,
        }}
        onClick={closeOverlay}
      >
        <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
          <g transform="translate(3.966 3.568)">
            <path
              d="m0-.001 17.435 18.213M0 18.212 17.435 0"
              style={{
                opacity: 1,
                fill: '#e67a45',
                stroke: '#e67a45',
                strokeWidth: 3.23161912,
                strokeLinecap: 'round',
                strokeMiterlimit: 4,
                fillOpacity: 1,
                strokeOpacity: 1,
              }}
            />
          </g>
        </svg>

        {/* <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 490 490'
          fill='none'
          stroke='#E67A45'
          width='24'
          height='24'
          strokeWidth='50px'
        >
          <path d='M11.387 490L245 255.832 478.613 490l10.826-10.826-233.63-234.178 233.63-234.185L478.613 0 245 234.161 11.387 0 .561 10.811l233.63 234.185L.561 479.174z' />
        </svg> */}
      </div>
      <div
        className="flex items-center justify-center w-full h-full"
        onClick={handleCloseOutside}
        data-overlay="true"
      >
        <div className={containerClassName}>
          {!ratio && (
            <div
              className="h-36 w-36 blink"
              style={{ position: 'absolute', zIndex: 3 }}
            ></div>
          )}
          {image && (
            <Image
              image={image}
              duration={500}
              background={false}
              className="w-full h-full"
              style={{
                zIndex: 2,
              }}
              imgStyle={{
                objectFit: 'contain',
                width: '100%',
                height: '100%',
              }}
              onLoad={handleLoad}
              width={1500}
            />
          )}
        </div>
      </div>
    </>
  )
}

const Grid = (props) => {
  const closeOverlay = () => {
    document.body.style.overflow = 'auto'
    props.setOverlay(null)
  }
  const handleOverlay = (image) => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    props.setOverlay(<Overlay image={image} closeOverlay={closeOverlay} />)
  }
  return (
    <>
      <div
        className="relative hidden h-full gap-2 overflow-hidden bg-white md:grid no-bars"
        style={{
          gridTemplateColumns: 'repeat(4, 1fr)',
          gridTemplateRows: 'repeat(3, 1fr)',
        }}
      >
        {Object.entries(props)
          .filter(([key]) => props[key].src)
          .sort(([a], [b]) => a.split('i')[1] - b.split('i')[1])
          .map(([, image], index) => (
            <div
              key={image.src}
              className="w-full h-full cursor-pointer"
              style={{ width: '100%', height: '100%' }}
              onClick={() => handleOverlay(image)}
              onKeyDown={() => handleOverlay(image)}
              role="button"
            >
              <Image
                image={image}
                background={true}
                delay={(index + 1) * 100}
                style={{
                  width: '100%',
                  height: '100%',
                }}
                imgStyle={{
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                width={300}
              />
            </div>
          ))}
      </div>
    </>
  )
}

export default Grid
