import React, { useMemo } from "react";
import Image from "components/Image";

const ArticleGrid = ({ items, location }) => {
  const hash = location.hash;
  return (
    <section className="grid gap-5 md:grid-cols-4">
      {/* {useMemo(() => { */}
      {/* return ( */}
      <>
        {items
          .filter((item) => {
            return item.categories.includes(`${hash.split("#")[1]}`) || !hash;
          })
          .map((item) => {
            const { image } = item.modules.find(
              (module) => module.component === "ArticleDetail"
            ).props;
            return (
              <a href={item.slug} key={item.slug}>
                <article
                  categories={JSON.stringify(item.categories)}
                  className="flex items-center justify-center w-full h-full p-6 border cursor-pointer md:col-span-1"
                >
                  <div className="overflow-hidden">
                    <Image
                      image={image}
                      className="w-full h-full"
                      background={true}
                      imgStyle={{
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        width: "12rem",
                        height: "12rem",
                      }}
                      width={300}
                    />
                  </div>
                </article>
              </a>
            );
          })}
      </>
      {/* ); */}
      {/* }, [location.hash])} */}
    </section>
  );
};

export default ArticleGrid;
