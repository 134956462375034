import React, { useRef, useEffect, useState } from 'react'
import { animated, useSprings } from 'react-spring'
import Image from 'components/Image'

const tileStyles = {
  boxSizing: 'border-box',
  padding: 5,
  zIndex: 0
}

const Mosaic = ({ img1, img2, ...res }) => {
  const mosaic = useRef()
  const [currentImage, setCurrentImage] = useState(1)
  const preserveRatio = () => {
    if (mosaic.current) {
      const { height, width } = mosaic.current.getBoundingClientRect()
      mosaic.current.style.height = `${width}px`
    }
  }

  const [styles, animate] = useSprings(12, () => ({
    background: 'transparent'
  }))

  useEffect(() => {
    if (currentImage === 2) {
      animate(index => {
        return {
          background: 'transparent',
          delay: index * 100
        }
      })
    }
  }, [currentImage])

  function startSlideshow () {
    setTimeout(() => {
      animate(index => {
        return {
          background: 'white',
          delay: index * 100,
          onRest: (item, controller) => {
            if (item.finished && index === styles.length - 1) {
              setCurrentImage(2)
            }
          }
        }
      })
    }, 4000)
  }
  // useEffect(() => {
  //   // preserveRatio();
  //   window.addEventListener("resize", preserveRatio);
  //   return () => {
  //     window.removeEventListener("resize", preserveRatio);
  //   };
  // }, []);

  const tileClassName = 'md:border-4 md:border-white'
  const onFirstImageLoaded = () => {
    setTimeout(() => {
      startSlideshow()
    }, 1500)
  }
  return (
    <div
      ref={mosaic}
      className='relative hidden h-full overflow-hidden md:grid md:flex bg:black md:bg-white no-bars'
      style={{
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridTemplateRows: 'repeat(3, 1fr)'
      }}
    >
      <Image
        image={img1}
        background={true}
        style={{
          // display: currentImage
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderCollapse: 'collapse',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: currentImage === 1 ? 1 : 0
        }}
        imgStyle={{
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderCollapse: 'collapse'
        }}
        width={1500}
        onLoad={onFirstImageLoaded}
      />
      <Image
        image={img2}
        background={true}
        style={{
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderCollapse: 'collapse',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: currentImage === 2 ? 1 : 0
        }}
        imgStyle={{
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderCollapse: 'collapse'
        }}
        width={1500}
        // onLoad={onImageLoaded}
      />
      {/* GRID */}
      {Array(12)
        .fill(1)
        .map((_, index) => {
          return (
            <animated.div
              style={{ ...tileStyles, ...styles[index] }}
              className={`${tileClassName}`}
              key={index}
            ></animated.div>
          )
        })}
    </div>
  )
}

export default Mosaic
