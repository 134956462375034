import React, { useState } from 'react'
import { animated, useSpring } from 'react-spring'
import ArticleGrid from 'components/ArticleGrid'

const MainSection = ({
  pageContext,
  navigate,
  path,
  overlayedPaths,
  location
}) => {
  const [Overlay, setOverlay] = useState(null)
  const styles = useSpring({
    opacity: Overlay ? 1 : 0,
    pointerEvents: Overlay ? 'all' : 'none',
    background: 'rgba(255, 255, 255, 1)',
    width: '100%'
  })
  if (Object.keys(pageContext).length === 0) {
    window.location = '/'
  }
  const mainModules = pageContext?.modules?.filter(
    m => m.props.side === 'center'
  )
  console.log('Path is and overlayed paths are', path, overlayedPaths, overlayedPaths.includes(path))
  return (
    <div
      // className={
      //   overlayedPaths.includes(path)
      //     ? 'tl-edges'
      //     : ''
      // }
    >
      {Overlay && (
        <animated.div
          style={{
            position: 'absolute',
            inset: 0,
            zIndex: 10,
            ...styles
          }}
        >
          {Overlay}
        </animated.div>
      )}
      {((typeof window !== 'undefined' && window.location.pathname === '/shop/') ||
        (typeof window !== 'undefined' && window.location.pathname === '/shop')) && (
          <ArticleGrid items={pageContext.relations} location={location} />
        )}
      {mainModules?.map((module, index, self) => {
        const {
          default: Component
        } = require(`components/${module.component}.js`)
        return (
          <React.Fragment key={`${module.component}-${index}`}>
            <Component
              {...module.props}
              overlayedPaths={overlayedPaths}
              navigate={navigate}
              path={path}
              pages={pageContext.pages}
              collections={pageContext.collections}
              setOverlay={setOverlay}
            />
            {self.length > 1 && <div className='h-2 md:h-12'></div>}
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default MainSection
