import React, { useState } from 'react'
import { Link, navigate } from 'gatsby'
import Text from 'components/Text'
import Image from 'components/Image'
import Arrow from 'components/Arrow'

const ArticleDetail = ({ image, description, ...rest }) => {
  const [ratio, setRatio] = useState(null)
  const handleLoad = image => {
    const { naturalWidth, naturalHeight } = image
    setRatio(naturalWidth / naturalHeight)
  }
  const containerClassName =
    ratio >= 1
      ? 'flex justify-center h-full py-2'
      : 'flex justify-center w-2/5 py-2'
  return (
    <article className='px-4 md:px-0'>
      <div
        className='flex items-center cursor-pointer'
        onClick={() => navigate(-1)}
      >
        {/* <Arrow
          polyline='15 18 9 12 15 6'
          width={24}
          height={24}
          strokeWidth={1}
        /> */}
        <Arrow polyline='15 18 9 12 15 6' width='32px' height='32px' />

        <h3 className='mt-1'>VOLVER</h3>
      </div>
      <div className={`mb-8 ${containerClassName}`}>
        <Image
          image={image}
          width={1500}
          onLoad={handleLoad}
          className='w-full h-full'
        />
      </div>
      <div>
        <Text text={description} />
      </div>
    </article>
  )
}

export default ArticleDetail
