import React from 'react'

const ShopInfo = props => {
  return (
    <a
      href={`mailto:${props.email.href}`}
      className='fixed flex flex-col items-center justify-center w-full md:absolute md:left-0 md:bottom-unset bottom-8 md:mt-56'
      style={
        {
          // right: '77vw'
        }
      }
    >
      <div className='flex items-center justify-center text-right uppercase'>
        <div className='mr-2' style={{ lineHeight: 1 }}>
          TO BUY
        </div>
        <div>
          <svg
            width='12'
            height='12'
            viewBox='0 0 12 12'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className='mr-2'
          >
            <path
              d='M12.5 6L0.5 12V0L12.5 6ZM1.04545 11.1575L11.3611 6L1.04545 0.8425V11.1575Z'
              fill='black'
            />
          </svg>
        </div>
      </div>
      <p className='font-bold uppercase text-primary'>{props.email.text}</p>
    </a>
  )
}

export default ShopInfo
