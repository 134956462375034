import React, { useRef, useEffect, useState } from "react";
import { animated, useSpring, config } from "react-spring";

const Image = ({
  style = {},
  className = "",
  image = { src: "", alt: "" },
  background = false,
  onLoad = () => {},
  imgStyle,
  delay = 0,
  duration = 200,
  width = 1500,
}) => {
  const overlayRef = useRef(null);
  const bgRef = useRef(null);
  const imgRef = useRef(null);
  const [img, setImg] = useState(image.thumbnail);
  const [springOverlay, apiOverlay] = useSpring(() => ({
    opacity: 1,
    filter: "blur(10px)",
  }));
const [springImage, apiImage] = useSpring(() => ({
    opacity: 0.5,
    filter: "blur(10px)",
  }));
  useEffect(() => {
    let observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          const imageSource = image.src.replace(
            "-size%7B{size}%7D",
            `-size%7B${width}%7D`
          );
          const loader = new window.Image();
          loader.onload = () => {
            apiOverlay.start({
              opacity: 0,
              delay,
              config: { ...config.molasses, duration },
            });
            apiImage.start({
              filter: "blur(0px)",
              opacity: 1,
              delay,
              config: { ...config.molasses, duration },
            });
            onLoad(loader);
            setImg(imageSource);
          };
          loader.src = imageSource;
        }
      },
      {
        threshold: 0,
        rootMargin: "0px",
      }
    );
    if (overlayRef.current) {
      observer.observe(overlayRef.current);
    }
    return () => {
      // posible bug de unmount y cambio de estado.
    };
  }, [image]);
  return (
    <div style={{ position: "relative", ...style }} className={className}>
      <animated.img
        src={image.thumbnail}
        ref={overlayRef}
        style={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          width: "100%",
          height: "100%",
          position: "absolute",
          ...springOverlay,
        }}
      />
      <>
        {background ? (
          <animated.div
            ref={bgRef}
            style={{
              width: "100%",
              height: "100%",
              backgroundImage: `url(${img})`,
              ...springImage,
              ...imgStyle,
            }}
          ></animated.div>
        ) : (
          <animated.img
            ref={imgRef}
            src={img}
            width={width}
            style={{ ...springImage, width: width, ...imgStyle }}
          />
        )}
      </>
    </div>
  );
};

export default Image;
