import React, { useEffect } from 'react'
import MainSection from 'templates/default'
import FAB from 'components/FAB'
import Helmet from 'react-helmet'

const Layout = ({ pageContext, navigate, path, location }) => {
  const sideModules = pageContext?.modules?.filter(m => m.props.side === 'left')
  const overlayedPaths = ['/', '//', '/us/', '/us']

  useEffect(() => {}, [path])

  const fab = pageContext?.modules.find(module => module.component === 'FAB')
  return (
    <>
      <Helmet>
        <title>Lavaka Producciones - {path.toUpperCase().replace('/', ' ').replace('-', ' ')}</title>
        <link rel="canonical" href="https://lavakapro.com" />
        <meta name="keywords" content="Diseño editorial, Fotolibro, Edición, Ediciones especiales, Primeras ediciones, Diseño gráfico, Arte, Fotografía, Curaduría, Producción editorial, Comunicación, Banco de imágenes, Tienda de arte, Tienda de fotografía, Galería, Librería, Poesía, Dibujo, Retrato, Autorretrato, Arte conceptual, Baños, Fotografía de calle, Fotografía urbana, Fotografía de ciudad, Archivos fotográficos, Músico, Exposiciones de fotografía, Registros fotográficos, Diseño de libros, Latinoamérica, Americana, Alemania, Berlin, España, Madrid, País Vasco, Euskalherria, Venezuela, Venezolano, Caracas, Diáspora, XXX, Editorial design, Photo book, Edition, Special editions, First editions, Graphic design, Art, Photography, Curation, Editorial production, Communication, Image Bank, Art store, Photo shop, Gallery, Bookstore, Poetry, He drew, Portrait, Self portrait, Conceptual art, Bathrooms, Street photography, Urban photography, City photography, Photo archives, Musician, Photography exhibitions, Photographic records, Book design, Latin America, American, Germany, Berlin, Spain, Madrid, Basque Country, Euskalherria, Venezuela, Venezuelan, Caracas, Diaspora" />
        <meta name="author" content="Lavaka producciones" />
      </Helmet>
      {fab && <FAB {...fab.props} />}
      <main className='min-h-screen gap-8 py-12 md:grid md:px-24 w-6xl md:grid-cols-main font-primary'>
        <div className='w-full' data-id='side' style={{ zIndex: 2 }}>
          {sideModules?.map((module, index) => {
            const {
              default: Component
            } = require(`components/${module.component}.js`)
            return (
              <div key={`${module.component}-${index}`} className='relative'>
                <Component
                  {...module.props}
                  navigate={navigate}
                  path={path}
                  pages={pageContext.pages}
                  categories={pageContext.categories}
                  collections={pageContext.collections}
                  overlayedPaths={overlayedPaths}
                  relations={pageContext.relations}
                  location={location}
                />
              </div>
            )
          })}
        </div>
        <MainSection
          overlayedPaths={overlayedPaths}
          pageContext={pageContext}
          path={path}
          navigate={navigate}
          location={location}
        />
      </main>
    </>
  )
}

export default Layout
