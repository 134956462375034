import React, { useEffect } from 'react'
import parse from 'html-react-parser'
import useMeasure from 'react-use-measure'

const SideText = props => {
  const [ref, { height: viewHeight }] = useMeasure()
  useEffect(() => {})
  return (
    <div
      className='flex flex-col items-end mt-12 text-xs text-right side-text mr-logoSm xl:mr-logo'
      ref={ref}
      style={{
        zIndex: 5
      }}
    >
      {parse(props.text)}
    </div>
  )
}

export default SideText
