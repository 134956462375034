import React from "react";
import Image from "components/Image";

export default function FAB({ image, link }) {
  return (
    <div className="fixed bottom-0 right-0 z-10 w-12 h-12 mr-6 cursor-pointer mb-14">
      <div className="relative">
        <Image image={image} />
        <a href={link.href} className="absolute inset-0" target="_blank"></a>
      </div>
    </div>
  );
}
